import styled from 'styled-components'

export const MenuSocial = styled.ul`
width: 100%;
margin: var(--spacing-8) 0;
padding: 0;
text-align: center;
list-style: none;
li {
  padding: 0.5rem 0;
}
h1 {
  margin: 0 0 0.5rem 0;
  font-size: 1.6rem;
}
p {
  margin: 0;
  font-size: 1.2rem;
}
a {
  color: white;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    color: white;
    text-decoration: underline;
  }
}
`

import React from 'react'

import { MenuSocial } from './MenuSocial.styled'
import { useSetRecoilState } from 'recoil'
import { recoilNavOpen } from '../../../recoil/app'
import useTranslation from '../../../hooks/useTranslation'

const Container = () => {
  const { lang, translate } = useTranslation()
  const setOpen = useSetRecoilState(recoilNavOpen)
  const clickHandler = e => setOpen(false)
  return <MenuSocial>
    <li>
      <h1>Suffolk &amp; North East Essex<br />Integrated Care Board (SNEE ICB)</h1>
      <p><a href='http://twitter.com/SNEE_ICS' target='_blank' rel='noopener noreferrer'>@SNEE_ICS</a></p>
      <p><a href='https://www.sneeics.org.uk/' target='_blank' rel='noopener noreferrer'>www.sneeics.org.uk</a></p>
    </li>
    <li>
      <h1><a onClick={clickHandler} href={`/${lang}${translate('/accessibility-information/')}`}>{translate('Accessibility Information')}</a></h1>
    </li>
    <li>
      <h1><a onClick={clickHandler} href={`/${lang}${translate('/privacy/')}`}>{translate('Privacy Policy')}</a></h1>
    </li>
  </MenuSocial>
}

export default Container

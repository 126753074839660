import styled, { createGlobalStyle } from 'styled-components'

export const EntryPageGlobalStyles = createGlobalStyle`
#___gatsby {
  ${p => p.featuredImage ? `
  background: #1a1a1a;
  background-image: url(${p.featuredImage});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  ` : ``}
}
#gatsby-focus-wrapper {
  background-color: rgba(0,0,0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
`

export const EntryPageOuter = styled.div`
position: fixed;
overflow-y: auto;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
${props => props.featuredImage ? `
background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${props.featuredImage.src});
background-position: center top;
background-repeat: no-repeat;
background-size: cover;
background-attachment: fixed;
` : ``}
`

export const EntryPageInner = styled.div`
width: calc(100% - var(--spacing-10));
@media (min-width: 768px) {
  width: calc(var(--breakpoint-sm) - var(--spacing-10));
}
`

export const EntryPageContainer = styled.div`
height: 90vh;
display: flex;
flex-direction: column;
// justify-content: center;
// align-items: center;

// outline: 1px solid white;
`

export const EntryPageContentOuter = styled.div`
margin-top: 2rem;
padding: var(--spacing-8) var(--spacing-4);
border-radius: var(--spacing-2);
background-color: rgba(255,255,255, 0.7);

display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;

overflow: hidden;
`

export const EntryPageContentInner = styled.div`
width: 100%;
padding: 0 1rem;
overflow-y: auto;
`

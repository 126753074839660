import React from 'react'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'

import { useSetRecoilState } from 'recoil'
import { recoilCookiesAccepted } from '../../recoil/app'
import { COOKIE_CONSENT_COOKIE_NAME } from '../../config/app'
import useTranslation from '../../hooks/useTranslation'

const CookieBanner = () => {
  const setCookiesAccepted = useSetRecoilState(recoilCookiesAccepted)
  const {translate} = useTranslation()
  if (getCookieConsentValue(COOKIE_CONSENT_COOKIE_NAME))
    return null
  else
    return <CookieConsent
      contentClasses=''
      location='bottom'
      buttonText={translate('Accept')}
      onAccept={() => setCookiesAccepted(true)}
      cookieName={COOKIE_CONSENT_COOKIE_NAME}>
        {translate('We use cookies to ensure that we give you the best experience using this resource. By continuing to use this resource, you agree to the use of cookies.')}
    </CookieConsent>
}

export default CookieBanner

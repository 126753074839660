import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import loadable from '@loadable/component'

import Seo from '../Seo'
import UserTypeSelector from './UserTypeSelector/UserTypeSelector'

import { useRecoilValue, useResetRecoilState } from 'recoil'
import { recoilMonolingual, recoilLang, recoilLangSelected } from '../../recoil/app'

import useFeaturedImage from '../../hooks/useFeaturedImage'
import useTranslation from '../../hooks/useTranslation'

import Logo from '../header/Logo/Logo'
import { EntryPageOuter, EntryPageInner, EntryPageGlobalStyles, EntryPageContainer, EntryPageContentOuter, EntryPageContentInner } from './EntryPage/EntryPage.styled'

const PWAPrompt = loadable(() => import('react-ios-pwa-prompt'))

const Button = styled.button`
margin: 0;
padding: 0;
border: 0;
color: var(--color-primary);
background-color: transparent;
word-break: break-word;
text-decoration: underline;
cursor: pointer;
&:hover,
&:focus {
  text-decoration: none;
}
`

const BackButton = () => {
  const monolingual = useRecoilValue(recoilMonolingual)
  const resetLang = useResetRecoilState(recoilLang)
  const resetLangSelected = useResetRecoilState(recoilLangSelected)
  const {translate} = useTranslation()
  const clickHandler = () => {
    resetLang()
    resetLangSelected()
  }
  if (monolingual)
    return null
  else
    return <p style={{marginBottom: 0}}>
      <Button onClick={clickHandler}>← {translate('Back')}</Button>
    </p>
}

const Selector = () => {
  const data = useStaticQuery(staticQuery)
  const {translate} = useTranslation()
  const featuredImage = useFeaturedImage(data?.wpPage)
  return <>
    <Seo />
    <EntryPageGlobalStyles featuredImage={featuredImage} />
    <EntryPageOuter>
      <EntryPageInner>
        <EntryPageContainer>
          <Logo />
          <EntryPageContentOuter>
            <EntryPageContentInner>
              {translate('User Type Selector introduction')}
              <UserTypeSelector />
              <BackButton />
            </EntryPageContentInner>
          </EntryPageContentOuter>
        </EntryPageContainer>
      </EntryPageInner>
    </EntryPageOuter>
    <PWAPrompt
      timesToShow={3}
      promptOnVisit={1}
      delay={600}
      copyTitle={translate('Add to Home Screen')}
      copyBody={translate('This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.')}
      copyShareButtonLabel={translate("1) Press the 'Share' button")}
      copyAddHomeButtonLabel={translate("2) Press 'Add to Home Screen'")}
      copyClosePrompt={translate('Cancel')}
      debug={false}
    />
  </>
}

export default Selector

const staticQuery = graphql`
  query UserTypeSelectorQuery {
    wpPage(template: {templateName: {eq: "Language Selector"}}) {
      id
      title
      slug
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
  `

import React, { useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Link, useStaticQuery, graphql } from 'gatsby'

import { MenuPrimary } from './MenuPrimary.styled'
import { recoilNavOpen, recoilLang } from '../../../recoil/app'

const Container = () => {
  const lang = useRecoilValue(recoilLang)
  const data = useStaticQuery(graphql`
    query MenuPrimaryQuery {
      allWpMenu {
        nodes {
          name
          slug
          menuItems {
            nodes {
              id
              url
              label
              target
            }
          }
        }
      }
    }
  `)
  const menuItems = useMemo(() => {
    if (data?.allWpMenu?.nodes?.find(node => node.slug === `primary-${lang}`))
      return data.allWpMenu.nodes.find(node => node.slug === `primary-${lang}`).menuItems.nodes
    return []
  }, [data, lang])
  const setOpen = useSetRecoilState(recoilNavOpen)
  const clickHandler = e => setOpen(false)
  return <MenuPrimary>
    {menuItems.map(({ id, url, label, target }) => <li key={`MenuPrimary/${id}`}>
      <Link to={url} onClick={clickHandler} target={target ? target : '_self'} rel={target ? 'noopener noreferrer' : ''}>
        {label}
      </Link>
    </li>)}
  </MenuPrimary>
}

export default Container

import styled from 'styled-components'

export const Outer = styled.div`
position: fixed;
overflow-y: auto;
width: 100%;
display: flex;
justify-content: center;
${p => p.backgroundColor ? `
background-color: var(--color-${p.backgroundColor});
` : ``}
${p => p.featuredImage ? `
background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${p.featuredImage});
background-position: center top;
background-repeat: no-repeat;
background-size: cover;
background-attachment: fixed;
` : ``}
`

export const Inner = styled.div`
width: calc(100% - var(--spacing-10));
@media (min-width: 768px) {
  width: calc(var(--breakpoint-sm) - var(--spacing-10));
}
`

import styled from 'styled-components'

export const Container = styled.div`
`

export const Form = styled.form`
display: flex;
justify-content: space-between;
align-items: center;
`

export const Input = styled.input`
padding: var(--spacing-2) var(--spacing-4);
border: 1px solid lightgray;
flex-grow: 1;
`

export const Button = styled.button`
padding: var(--spacing-2) var(--spacing-4);
border: 1px solid lightgray;
cursor: pointer;
`

import React from 'react'

import Limits from '../../layout/Limits/Limits'
import {Header, HeaderBackground} from './Header.styled'

import Logo from '../Logo/Logo'
import SkipToContent from '../SkipToContent'
import LanguageSwitcher from '../LanguageSwitcher'
import SearchButton from '../SearchButton'
import NavigationButton from '../NavigationButton'

import { useRecoilValue } from 'recoil'
import { recoilAppReady } from '../../../recoil/app'

const Container = () => {
  const ready = useRecoilValue(recoilAppReady)
  return <>
    <Header role='banner'>
      <Limits>
        <Logo />
        <SkipToContent />
        {ready && <>
          <LanguageSwitcher />
          <SearchButton />
          <NavigationButton />
        </>}
      </Limits>
    </Header>
    <HeaderBackground />
  </>
}

export default Container

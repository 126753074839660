import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Seo from '../Seo'
import LanguageSelector from './LanguageSelector/LanguageSelector'

import useFeaturedImage from '../../hooks/useFeaturedImage'

import Logo from '../header/Logo/Logo'
import { EntryPageOuter, EntryPageInner, EntryPageGlobalStyles, EntryPageContainer, EntryPageContentOuter, EntryPageContentInner } from './EntryPage/EntryPage.styled'

const Selector = () => {
  const data = useStaticQuery(staticQuery)
  const featuredImage = useFeaturedImage(data?.wpPage)
  return <>
    <Seo />
    <EntryPageGlobalStyles featuredImage={featuredImage} />
    <EntryPageOuter>
      <EntryPageInner>
        <EntryPageContainer>
          <Logo />
          <EntryPageContentOuter>
            <EntryPageContentInner>
              <LanguageSelector />
            </EntryPageContentInner>
          </EntryPageContentOuter>
        </EntryPageContainer>
      </EntryPageInner>
    </EntryPageOuter>
  </>
}

export default Selector

const staticQuery = graphql`
  query LanguageSelectorQuery {
    wpPage(template: {templateName: {eq: "Language Selector"}}) {
      id
      title
      slug
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
  `

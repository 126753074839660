import styled from 'styled-components'

export const LogoContainer = styled.header`
display: flex;
// flex-grow: 1;
a,
img {
  max-height: 3rem;
  display: inline-flex;
}
img {
  display: block;
}
.logo-image {
  display: none;
}
@media(min-width: 413.98px) {
  .logo-image {
    display: block;
  }
  .logo-image-sm {
    display: none;
  }
}
${p => p.ready ? `
display: flex;
justify-content: flex-start;
` : `
.logo-image {
  display: block;
}
.logo-image-sm {
  display: none;
}
`}
`

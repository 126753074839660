import LANG from '../config/lang'
import {useRecoilValue} from "recoil"
import {recoilLang} from "../recoil/app"

export const translate = (string, lang) => {
  if (LANG[string] && LANG[string][lang])
    return LANG[string][lang]
  if (LANG[string] && LANG[string]['en'])
    return LANG[string]['en']
  return string
}

const useTranslation = () => {
  const lang = useRecoilValue(recoilLang)
  const translateCurrent = string => translate(string, lang)
  return {lang, translate: translateCurrent}
}

export default useTranslation

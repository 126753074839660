import styled from 'styled-components'

export const MenuPrimary = styled.ul`
width: 100%;
margin: var(--spacing-8) 0;
padding: 0;
list-style: none;
font-size: var(--fontSize-5);
li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--color-secondary-contrast);
  &:last-child {
    border-bottom: 0;
  }
}
a {
  width: 100%;
  color: var(--color-secondary-contrast);
  line-height: var(--lineHeight-loose);
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  &:hover,
  &:focus,
  &:active {
    color: var(--color-primary);
    background-color: var(--color-secondary-contrast);
  }
}
`

import React from 'react'
import { useRecoilValue } from 'recoil'

import { recoilLangSelected, recoilUserTypeSelected } from '../recoil/app'
import Language from './selectors/Language'
import UserType from './selectors/UserType'
import Main from './layout/Main/Main'
import Analytics from './tracking/Analytics'
import Header from './header/Header/Header'
import Navigation from './navigation/Navigation/Navigation'
import CookieBanner from './tracking/CookieBanner'
// import Debug from './Debug'

const Layout = ({ featuredImage, children }) => {
  const langSelected = useRecoilValue(recoilLangSelected)
  const userTypeSelected = useRecoilValue(recoilUserTypeSelected)
  return <>
    {(langSelected && userTypeSelected) && <>
      <Header />
      <Navigation />
      <Main featuredImage={featuredImage}>
        {children}
      </Main>
      <CookieBanner />
      <Analytics />
    </>}
    {(langSelected && !userTypeSelected) && <UserType />}
    {(!langSelected && !userTypeSelected) && <Language />}
    {/* <Debug /> */}
  </>
}

export default Layout

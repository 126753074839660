import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { parse } from '@vanillaes/csv'

import { DEV_URL, GOOGLE_ANALYTICS_ID, COOKIE_CONSENT_COOKIE_NAME, USER_TYPE_NAMES, DATA_FILES } from '../../config/app'
import { recoilCCG, recoilData, recoilLang, recoilPostcode, recoilUserType } from '../../recoil/app'

const getConsentCookie = () => {
  if (!!document.cookie === false)
    return false

  const cookies = document.cookie.split(';')
  const cookie = cookies.find(_ => _.trim().startsWith(`${COOKIE_CONSENT_COOKIE_NAME}=`))

  if (!cookie)
    return false

  const [ name, value ] = cookie.trim().split('=')

  return name === COOKIE_CONSENT_COOKIE_NAME && value === 'true'
}

const Analytics = () => {
  const lang = useRecoilValue(recoilLang)
  const type = useRecoilValue(recoilUserType)
  const postcode = useRecoilValue(recoilPostcode)
  const ccg = useRecoilValue(recoilCCG)
  const ccgNames = useRecoilValue(recoilData(DATA_FILES.CCG))
  useEffect(() => {
    if (window.location.host !== DEV_URL && ccgNames.length && getConsentCookie()) {
      const ccgName = ccg ? parse(ccgNames).find(([code]) => code === ccg)[1] : ''

      const body = document.getElementsByTagName('body')[0]

      if (!document.getElementById('gtag-embed')) {
        const tag = document.createElement('script')
        tag.id = 'gtag-embed'
        tag.async = false
        tag.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`
        body.appendChild(tag)
      }

      if (document.getElementById('gtag-script')) {
        const el = document.getElementById('gtag-script')
        el.parentNode.removeChild(el)
      }

      const script = document.createElement('script')
      script.id = 'gtag-script'
      const src = document.createTextNode(`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments)};
      gtag('js', new Date());
      gtag('set', 'user_properties', {
        'user_lang': '${lang}',
        'user_type': '${USER_TYPE_NAMES[type]}',
        'user_postcode': '${postcode}',
        'user_ccg': '${ccg}',
        'user_ccg_name': '${ccgName}',
      })
      gtag('event', 'user_data', {
        'user_lang': '${lang}',
        'user_type': '${USER_TYPE_NAMES[type]}',
        'user_postcode': '${postcode}',
        'user_ccg': '${ccg}',
        'user_ccg_name': '${ccgName}',
      })
      gtag('config', '${GOOGLE_ANALYTICS_ID}');`)
      script.appendChild(src)
      body.appendChild(script)
    }
  }, [lang, type, postcode, ccg, ccgNames])

  return null
}

export default Analytics

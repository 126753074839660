import styled from 'styled-components'

export const HorizontalBreak = styled.hr`
margin: 2rem 0;
border: 0;
border-top: 1px solid gray;
`

export const Select = styled.select`
width: 100%;
padding: 0.5rem 1rem;
border: 0;
cursor: pointer;
`

export const Acknowledge = styled.input`
margin-right: 0.5rem;
`

export const Continue = styled.button`
width: 100%;
padding: 0.5rem 1rem;
border: 0;
cursor: pointer;
`

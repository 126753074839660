import React from 'react'
import { useRecoilValue } from 'recoil'

import Limits from '../../layout/Limits/Limits'
import MenuPrimary from '../MenuPrimary/MenuPrimary'
import MenuSocial from '../MenuSocial/MenuSocial'
import { Navigation } from './Navigation.styled'
import { recoilNavOpen } from '../../../recoil/app'
import useTranslation from '../../../hooks/useTranslation'

const Container = () => {
  const {translate} = useTranslation()
  const open = useRecoilValue(recoilNavOpen)
  return <Navigation role='navigation' aria-label={translate('Site Navigation')} open={open}>
    <Limits>
      <MenuPrimary />
      <MenuSocial />
    </Limits>
  </Navigation>
}

export default Container

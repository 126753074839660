import React from 'react'
import { Outer, Inner } from './Limits.styled'

const Limits = ({ featuredImage, backgroundColor, children }) => {
  return <Outer className={`outer ${featuredImage ? `hasFeaturedImage` : ``}`} featuredImage={featuredImage} backgroundColor={backgroundColor}>
    <Inner className='inner'>
      {children}
    </Inner>
  </Outer>
}

export default Limits

import styled from 'styled-components'
import { animated } from 'react-spring'

export const ErrorList = styled(animated.ul)`
margin: 0 0 2rem 0;
padding: 0.5rem 1rem !important;
border: 1px solid rgba(255,0,0, 0.2);
color: #c00;
background-color: rgba(255,0,0, 0.1);
list-style: none;
transform-origin: 50% 0;
`

export const ErrorListItem = styled(animated.li)`
margin: 0.5rem 0;
padding: 0 !important;
&:before {
  display: none;
}
`

import React from 'react'
import { useSpring, useTrail } from 'react-spring'

import { ErrorList, ErrorListItem } from './ErrorMessages.styled'

const ErrorMessages = ({errors}) => {
  const style = useSpring({from: {opacity: 0}, to: {opacity: 1}})
  const trail = useTrail(errors.length, {
    from:    {opacity: 0, x: -20},
    opacity: 1,
    x:       0,
    config:  {mass: 5, tension: 2000, friction: 200},
    delay:   200,
  })
  return <ErrorList style={style}>
    {trail.map((style, idx) =>
      <ErrorListItem key={idx} style={style}>
        {errors[idx]}
      </ErrorListItem>)}
  </ErrorList>
}

const Container = ({errors}) => {
  if (!errors.length)
    return null
  else
    return <ErrorMessages errors={errors} />
}

export default Container

export const DEBUG = {
  STATE: false,
  RESET: false,
}

export const USE_SECTION_INDEX_NEXT_INDEX_LINKS = true

export const LINKS = {
  SECTION_INDEX: {
    NEXT_INDEX: true,
  },
  SECTION: {
    NEXT:       true,
    NEXT_INDEX: true,
  },
}

export const DATA_TIMESTAMP = 1630905132

export const DEV_URL = 'localhost:8000'

export const GOOGLE_ANALYTICS_ID = 'G-BZPNGYZZ5B'

export const COOKIE_CONSENT_COOKIE_NAME = 'gatsby-gdpr-google-analytics'

export const MONOLINGUAL = false

export const DEFAULT_LANGUAGE = 'en'

export const USER_TYPES = {
  PROFESSIONAL: 1,
  CARER:        2,
  OTHER:        3,
}

export const USER_TYPE_NAMES = {
  [USER_TYPES.PROFESSIONAL]: 'Professional',
  [USER_TYPES.CARER]:        'Carer',
  [USER_TYPES.OTHER]:        'Other',
}

export const DATA_FILES = {
  GP:  '/csv/epraccur.csv',
  CCG: '/csv/eccg.csv',
}

import React from 'react'

import Limits from '../Limits/Limits'
import { Main } from './Main.styled'

const Container = ({ featuredImage, children }) => {
  return <Limits featuredImage={featuredImage} backgroundColor={'background'}>
    <Main role='main' id='content'>
      {children}
    </Main>
  </Limits>
}

export default Container

import React from 'react'

const LANG = {
  'Add to Home Screen': {
    en: 'Add to Home Screen',
    pl: 'Dodaj do ekranu głównego',
    ro: 'Adăugarea la ecranul principal',
    pt: 'Adicionar à tela inicial',
    lt: 'Įtraukti į pagrindinį ekraną',
    ru: 'Добавить на главный экран',
  },
  'This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.': {
    en: 'This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.',
    pl: 'Ta strona internetowa ma funkcjonalność aplikacji. Dodaj go do ekranu głównego, aby używać go na pełnym ekranie i w trybie offline.',
    ro: 'Acest site web are funcționalitatea aplicației. Adăugați-l la ecranul de pornire pentru a-l utiliza pe tot ecranul și în timp ce sunteți offline.',
    pt: 'Este site tem funcionalidade de aplicativo. Adicione-o à sua tela inicial para usá-lo na tela cheia e enquanto estiver offline.',
    lt: 'Ši svetainė turi programos funkciją. Įtraukite jį į pagrindinį ekraną, kad galėtumėte jį naudoti visame ekrane ir neprisijungę.',
    ru: 'Этот веб-сайт имеет функциональность приложения. Добавьте его на главный экран, чтобы использовать его в полноэкранном режиме и в автономном режиме.',
  },
  "1) Press the 'Share' button": {
    en: "1) Press the 'Share' button",
    pl: "1) Naciśnij przycisk 'Udostępnij'",
    ro: "1) Apăsați butonul 'Share'",
    pt: "1) Pressione o botão 'Compartilhar'",
    lt: "1) Paspauskite mygtuką 'Bendrinti'",
    ru: "1) Нажмите кнопку «Поделиться»",
  },
  "2) Press 'Add to Home Screen'": {
    en: "2) Press 'Add to Home Screen'",
    pl: "2) Naciśnij 'Dodaj do ekranu głównego'",
    ro: "2) Apăsați pe 'Adăugați la ecranul de pornire'",
    pt: "2) Pressione 'Adicionar à tela inicial'",
    lt: "2) Paspauskite 'Pridėti prie pagrindinio ekrano'",
    ru: "2) Нажмите «Добавить на главный экран»",
  },
  'Cancel': {
    en: 'Cancel',
    pl: 'Anuluj',
    ro: 'Anula',
    pt: 'Cancelar',
    lt: 'Atšaukti',
    ru: 'Отмена',
  },
  'Change': {
    en: 'Change',
    pl: 'Zmień',
    ro: 'Modificați',
    pt: 'Alterar',
    lt: 'Keisti',
    ru: 'Изменить',
  },
  'No contact details could be found for the ICB': {
    en: 'No contact details could be found for the ICB',
    pl: 'Nie znaleziono danych kontaktowych ICB',
    ro: 'Nu s-au găsit date de contact pentru ICB',
    pt: 'Nenhum contacto de ICB encontrado',
    lt: 'Nepavyko rasti ICB kontaktinių duomenų',
    ru: 'Не удалось найти контактную информацию для ICB',
  },
  'Please select your ICB:': {
    en: 'Please select your ICB:',
    pl: 'Proszę wybrać ICB:',
    ro: 'Vă rugăm să selectați ICB-ul dvs.:',
    pt: 'Selecione o seu ICB:',
    lt: 'Pasirinkite savo ICB:',
    ru: 'Выберите свою ICB:',
  },
  'Postcode': {
    en: 'Postcode',
    pl: 'Kod pocztowy',
    ro: 'Cod poștal',
    pt: 'Código postal',
    lt: 'Pašto kodas',
    ru: 'Почтовый индекс',
  },
  'Please enter the first part of your postcode:': {
    en: 'Please enter the first part of your postcode:',
    pl: 'Proszę wprowadzić pierwszą część kodu pocztowego:',
    ro: 'Introduceți prima parte a codului poștal:',
    pt: 'Introduza a primeira parte do seu código postal:',
    lt: 'Įveskite pirmąją pašto kodo dalį:',
    ru: 'Пожалуйста, введите первую часть вашего почтового индекса:',
  },
  'User Type Selector introduction': {
    en: <>
      <p>Welcome to icaredementia where you can learn more about how to support people with dementia though practical information and advice to support their health and wellbeing.  There are also resources for carers and links to local support networks.</p>
      <p>The information within this resource is not meant for use in a clinical emergency or with a individual who is acutely unwell.</p>
      <p>The Care Act statutory guidance defines Adult Safeguarding as: ‘Protecting an adult’s right to live in safety, free from abuse and neglect’. If you would like to discuss whether the situation you are concerned about should be the subject of a safeguarding referral, please contact the MASH Consultation Line on <a href='tel:03456061499'>0345 606 1499</a> or if you would like an update on a referral you have made, please email <a href='mailto:ACSMASHDuty@suffolk.gov.uk'>ACSMASHDuty@suffolk.gov.uk</a>.</p>
    </>,
    pl: <>
      <p>Witamy w icaredementia, gdzie możesz dowiedzieć się więcej o tym, jak wspierać osoby z demencją, dzięki praktycznym informacjom i poradom wspierającym ich zdrowie i dobre samopoczucie. Dostępne są również zasoby dla opiekunów i linki do lokalnych sieci wsparcia.</p>
      <p>Informacje zawarte w tych zasobach nie są przeznaczone do wykorzystania w nagłych wypadkach klinicznych ani w przypadku osób z ostrym stanem chorobowym.</p>
      <p>Ustawowe wytyczne wynikające z Ustawy o opiece określają ochronę dorosłych jako: „Ochronę prawa osoby dorosłej do bezpiecznego życia, bez przemocy i zaniedbań”. Aby wspólnie zastanowić się, czy niepokojąca sytuacja powinna zostać zgłoszona w ramach ochrony przed zagrożeniem dla bezpieczeństwa, należy skontaktować się z linią konsultacyjną MASH pod numerem <a href='tel:03456061499'>0345 606 1499</a>. Aby uzyskać informacje dotyczące złożonego zgłoszenia, proszę wysłać wiadomość e-mail na adres <a href='mailto:ACSMASHDuty@suffolk.gov.uk'>ACSMASHDuty@suffolk.gov.uk</a>.</p>
    </>,
    ro: <>
      <p>Bine ați venit la icaredementia, unde puteți afla mai multe despre cum să sprijiniți persoanele cu demență prin informații practice și sfaturi pentru a le susține sănătatea și bunăstarea. Există, de asemenea, resurse pentru îngrijitori și linkuri către rețelele locale de asistență.</p>
      <p>Informațiile din această resursă nu sunt destinate utilizării în caz de urgență clinică sau pentru o persoană care se află în stare acută.</p>
      <p>Îndrumările legale din Legea asistenței definesc protecția adulților ca: „Protejarea dreptului unui adult de a trăi în siguranță, fără abuz și neglijență”. În cazul în care doriți să discutați dacă situația care vă îngrijorează ar trebui să facă obiectul unei sesizări de protecție, vă rugăm să contactați Linia de consultații MASH la <a href='tel:03456061499'>0345 606 1499</a> sau dacă doriți informații actualizate cu privire la o sesizare pe care ați făcut-o, vă rugăm să trimiteți un e-mail <a href='mailto:ACSMASHDuty@suffolk.gov.uk'>ACSMASHDuty@suffolk.gov.uk</a>.</p>
    </>,
    pt: <>
      <p>Bem-vindo ao icaredementia, onde pode aprender mais sobre como apoiar as pessoas com demência através de informações práticas e conselhos para apoiar a sua saúde e bem-estar. Existem também recursos para os prestadores de cuidados e ligações a redes de apoio locais.</p>
      <p>As informações contidas neste recurso não se destinam a ser utilizadas numa emergência clínica ou com uma pessoa com alterações agudas.</p>
      <p>A orientação legal da Lei de Cuidados define a Proteção de Adultos como: "Proteger o direito de um adulto a viver em segurança, livre de abuso e negligência". Se gostaria de discutir se a situação com que está preocupado(a) deve ser objeto de uma referência de salvaguarda, contacte a Linha de Consulta MASH através do <a href='tel:03456061499'>0345 606 1499</a>, ou se gostaria de uma atualização sobre uma referência que fez, envie um e-mail para <a href='mailto:ACSMASHDuty@suffolk.gov.uk'>ACSMASHDuty@suffolk.gov.uk</a>.</p>
    </>,
    lt: <>
      <p>Sveiki atvykę į icaredementia, kur galite sužinoti daugiau apie tai, kaip padėti demencija sergantiems žmonėms, naudodamiesi praktine informacija ir patarimais, kaip palaikyti jų sveikatą ir gerovę. Taip pat yra išteklių slaugytojams ir nuorodų į vietinius paramos tinklus.</p>
      <p>Šiame šaltinyje esanti informacija nėra skirta naudoti esant skubios medicininės pagalbos poreikiui arba žmogui staiga pasijutus blogai.</p>
      <p>Priežiūros įstatyme pateiktuose nurodymuose Suaugusiojo apsauga apibrėžta taip: „Suaugusiojo teisės gyventi saugiai, nepatiriant prievartos ir nepriežiūros, apsauga“. Jei norėtumėte aptarti, ar situacija, dėl kurios nerimaujate, turėtų būti laikoma susidariusiu apsaugos poreikiu, kreipkitės į MASH konsultacinę liniją <a href='tel:03456061499'>0345 606 1499</a>, o jei norite gauti naujausią informaciją apie jūsų pateiktą kreipimąsi, rašykite el. paštu <a href='mailto:ACSMASHDuty@suffolk.gov.uk'>ACSMASHDuty@suffolk.gov.uk</a>.</p>
    </>,
    ru: <>
      <p>Добро пожаловать в icaredementia, где вы можете узнать больше о том, как поддержать людей с деменцией, с помощью практической информации и советов для поддержания их здоровья и благополучия. Кроме того, здесь есть ресурсы для лиц, осуществляющих уход, и ссылки на местные сети поддержки.</p>
      <p>Информация, содержащаяся в данном ресурсе, не предназначена для использования в экстренных случаях или для людей с острым течением заболевания.</p>
      <p>Закон об уходе определяет защиту взрослых как: "Защита права взрослого человека жить в безопасности, не подвергаясь жестокому обращению и пренебрежению". Если вы хотите обсудить, должна ли ситуация, которая вас беспокоит, быть предметом направления на защиту, пожалуйста, свяжитесь с консультационной линией MASH по телефону <a href='tel:03456061499'>0345 606 1499</a>, или если вы хотите получить обновленную информацию о выполненном направлении, пожалуйста, напишите нам по электронной почте <a href='mailto:ACSMASHDuty@suffolk.gov.uk'>ACSMASHDuty@suffolk.gov.uk</a></p>
    </>,
  },
  'Professional': {
    en: 'Professional',
    pl: 'Specjalista',
    ro: 'Cadru medical',
    pt: 'Profissional',
    lt: 'Specialistas',
    ru: 'Профессионал',
  },
  'Carer': {
    en: 'Carer',
    pl: 'Opiekun',
    ro: 'Îngrijitor',
    pt: 'Cuidador/a',
    lt: 'Prižiūrėtojas',
    ru: 'Опекун',
  },
  'Other': {
    en: 'Other',
    pl: 'Inny',
    ro: 'Altele',
    pt: 'Outro/a',
    lt: 'Kita',
    ru: 'Другое лицо',
  },
  'Please select a user type': {
    en: 'Please select a user type',
    pl: 'Proszę wybrać typ użytkownika',
    ro: 'Vă rugăm să selectați un tip de utilizator',
    pt: 'Selecione um tipo de utilizador/a',
    lt: 'Pasirinkite naudotojo tipą',
    ru: 'Пожалуйста, выберите тип пользователя',
  },
  'Please check the box to acknowledge the terms and conditions above': {
    en: 'Please check the box to acknowledge the terms and conditions above',
    pl: 'Zaznacz pole, aby potwierdzić powyższe warunki',
    ro: 'Vă rugăm să bifați caseta pentru a confirma termenii și condițiile de mai sus',
    pt: 'Verifique a caixa para reconhecer os termos e condições acima',
    lt: 'Pažymėkite šį langelį, kad galėtumėte patvirtinti aukščiau nurodytas sąlygas',
    ru: 'Пожалуйста, установите флажок, чтобы подтвердить условия и положения выше',
  },
  'Please indicate whether you are a professional, carer or other:': {
    en: 'Please indicate whether you are a professional, carer or other:',
    pl: 'Proszę wskazać, czy jest Pan/Pani specjalistą, opiekunem czy inną osobą:',
    ro: 'Vă rugăm să indicați dacă sunteți cadru medical, îngrijitor sau altceva:',
    pt: 'Indique se é um profissional, cuidador/a ou outro/a:',
    lt: 'Nurodykite, kas esate: specialistas, prižiūrėtojas ar kt:',
    ru: 'Пожалуйста, укажите, являетесь ли вы профессионалом, опекуном или другим лицом:',
  },
  'Please tick to acknowledge': {
    en: 'Please tick to acknowledge',
    pl: 'Proszę zaznaczyć, aby potwierdzić',
    ro: 'Vă rugăm să bifați pentru confirmare',
    pt: 'Assinale para confirmar',
    lt: 'Pažymėkite, kad patvirtintumėtee',
    ru: 'Пожалуйста, отметьте для подтверждения',
  },
  'Choose...': {
    en: 'Choose...',
    pl: 'Proszę wybrać...',
    ro: 'Alegeți...',
    pt: 'Escolher...',
    lt: 'Pasirinkti...',
    ru: 'Выбрать...',
  },
  'Continue': {
    en: 'Continue',
    pl: 'Dalej',
    ro: 'Continuați',
    pt: 'Continuar',
    lt: 'Tęsti',
    ru: 'Продолжить',
  },
  'Back': {
    en: 'Back',
    pl: 'Z powrotem',
    ro: 'Înapoi',
    pt: 'Voltar',
    lt: 'Atgal',
    ru: 'Назад',
  },
  'Read More': {
    en: 'Read More',
    pl: 'Czytaj więcej',
    ro: 'Citeste mai mult',
    pt: 'Consulte Mais informação',
    lt: 'Skaityti daugiau',
    ru: 'Читать далее',
  },
  'Accept': {
    en: 'Accept',
    pl: 'Zaakceptować',
    ro: 'Accept',
    pt: 'Aceitar',
    lt: 'Priimti',
    ru: 'Принимать',
  },
  'We use cookies to ensure that we give you the best experience using this resource. By continuing to use this resource, you agree to the use of cookies.': {
    en: <>
          We use cookies to ensure that we give you the best experience using this resource. By continuing to use this resource, you agree to the use of cookies.
          Review our <a href='https://www.westsuffolkccg.nhs.uk/about-us/privacy-policy/' target='_blank' rel='noreferrer' style={{ color: 'white' }}>Privacy Policy</a> for further details.
        </>,
    pl: <>
          Używamy plików cookie, aby zapewnić najlepszą jakość korzystania z tego zasobu. Kontynuując korzystanie z tego zasobu, zgadzasz się na użycie plików cookie.
          Aby uzyskać więcej informacji, zapoznaj się z naszą <a href='https://www.westsuffolkccg.nhs.uk/about-us/privacy-policy/' target='_blank' rel='noreferrer' style={{ color: 'white' }}>Polityka prywatności</a>.
        </>,
    ro: <>
          Folosim cookie-uri pentru a ne asigura că vă oferim cea mai bună experiență folosind această resursă. Continuând să utilizați această resursă, sunteți de acord cu utilizarea cookie-urilor.
          Consultați <a href='https://www.westsuffolkccg.nhs.uk/about-us/privacy-policy/' target='_blank' rel='noreferrer' style={{ color: 'white' }}>Politica de confidențialitate</a> pentru detalii suplimentare.
        </>,
    pt: <>
          Usamos cookies para garantir que oferecemos a melhor experiência no uso deste recurso. Ao continuar a usar este recurso, você concorda com o uso de cookies.
          Reveja a nossa <a href='https://www.westsuffolkccg.nhs.uk/about-us/privacy-policy/' target='_blank' rel='noreferrer' style={{ color: 'white' }}>Política de Privacidade</a> para obter mais detalhes.
        </>,
    lt: <>
          Mes naudojame slapukus tam, kad užtikrintume, kad mes suteikiame jums geriausią patirtį naudojant šį šaltinį. Toliau naudodamiesi šiuo šaltiniu sutinkate naudoti slapukus.
          Norėdami gauti daugiau informacijos, peržiūrėkite mūsų <a href='https://www.westsuffolkccg.nhs.uk/about-us/privacy-policy/' target='_blank' rel='noreferrer' style={{ color: 'white' }}>privatumo politiką</a>.
        </>,
    ru: <>
          Мы используем файлы cookie, чтобы обеспечить максимальное удобство использования этого ресурса. Продолжая использовать этот ресурс, вы соглашаетесь на использование файлов cookie.
          Ознакомьтесь с нашей <a href='https://www.westsuffolkccg.nhs.uk/about-us/privacy-policy/' target='_blank' rel='noreferrer' style={{ color: 'white' }}>Политикой конфиденциальности</a> для получения дополнительных сведений.
        </>,
  },
  'Search': {
    en: 'Search',
    pl: 'Szukaj',
    ro: 'Căutare',
    pt: 'Procurar',
    lt: 'Paieška',
    ru: 'Поиск',
  },
  'Enter terms to search for': {
    en: 'Enter terms to search for',
    pl: 'Wpisz terminy do wyszukania',
    ro: 'Introduceți termenii de căutat',
    pt: 'Insira os termos para pesquisar',
    lt: 'Įveskite terminus, kurių norite ieškoti',
    ru: 'Введите условия поиска',
  },
  'Please enter 3 or more characters': {
    en: 'Please enter 3 or more characters',
    pl: 'Wprowadź 3 lub więcej znaków',
    ro: 'Vă rugăm să introduceți 3 sau mai multe caractere',
    pt: 'Por favor, digite 3 ou mais caracteres',
    lt: 'Įveskite 3 ar daugiau simbolių',
    ru: 'Пожалуйста, введите 3 или более символов',
  },
  'No results found': {
    en: 'No results found',
    pl: 'Nie znaleziono wyników',
    ro: 'Nici un rezultat gasit',
    pt: 'Nenhum resultado encontrado',
    lt: 'Nieko nerasta',
    ru: 'результаты не найдены',
  },
  'Accessibility Information': {
    en: 'Accessibility Information',
    pl: 'Informacje o ułatwieniach dostępu',
    ro: 'Informații despre accesibilitate',
    pt: 'Informações sobre acessibilidade',
    lt: 'Pritaikymo neįgaliesiems informacija',
    ru: 'Информация о специальных возможностях',
  },
  'Privacy Policy': {
    en: 'Privacy Policy',
    pl: 'Polityka prywatności',
    ro: 'Politica de confidențialitate',
    pt: 'Política de Privacidade',
    lt: 'Privatumo politika',
    ru: 'Политика конфиденциальности',
  },
  '/privacy/': {
    en: '/privacy/',
    pl: '/polityka-prywatnosci/',
    ro: '/politica-de-confidentialitate/',
    pt: '/politica-de-privacidade/',
    lt: '/privatumo-politika/',
    ru: '/politika-konfidentsialnosti/',
  },
  '/accessibility-information/': {
    en: '/accessibility-information/',
    pl: '/informacje-o-dostepnosci/',
    ro: '/informatii-privind-accesibilitatea/',
    pt: '/informacoes-de-acessibilidade/',
    lt: '/prieinamumo-informacija/',
    ru: '/информация-о-доступности/',
  },
  'Skip to content': {
    en: 'Skip to content',
    pl: 'Przejdź do treści',
    ro: 'Sari la conținut',
    pt: 'Ir para o conteúdo',
    lt: 'Pereiti prie turinio',
    ru: 'перейти к содержанию',
  },
  'Choose language': {
    en: 'Choose language',
    pl: 'Wybierz język',
    ro: 'Alege limba',
    pt: 'Escolha o seu idioma',
    lt: 'Pasirinkite kalbą',
    ru: 'Выберите язык',
  },
  'Site Navigation': {
    en: 'Site Navigation',
    pl: 'Nawigacja witryny',
    ro: 'Navigare pe site',
    pt: 'Navegação no site',
    lt: 'Svetainės navigacija',
    ru: 'Навигация по сайту',
  },
}

export default LANG

import React, { useRef } from 'react'
import { useRecoilState } from 'recoil'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { Button } from './Button.styled'
import { recoilNavOpen } from '../../recoil/app'
import useTranslation from '../../hooks/useTranslation'

const NavigationButton = () => {
  const {translate} = useTranslation()
  const [ open, setOpen ] = useRecoilState(recoilNavOpen)
  const ref = useRef()
  const clickHandler = e => {
    e.preventDefault()
    ref.current.blur()
    setOpen(open => !open)
  }
  return <Button aria-label={translate('Site Navigation')} onClick={clickHandler} open={open} ref={ref}>
    <FontAwesomeIcon icon={open ? faTimes : faBars} size='2x' />
  </Button>
}

export default NavigationButton

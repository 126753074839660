import { graphql, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'
import { useSetRecoilState } from 'recoil'
import { recoilLang, recoilLangSelected } from '../../../recoil/app'
import { List, ListItem, ListLink } from './LanguageSelector.styled'

const LanguageSelector = () => {
  const data = useStaticQuery(graphql`
    query LanguageSelector {
      languages: allWpPage(filter: {parentDatabaseId: {eq: null}, slug: {ne: "language-selector"}}) {
        nodes {
          title
          uri
          slug
        }
      }
    }
  `)
  const setLang = useSetRecoilState(recoilLang)
  const setLangSelected = useSetRecoilState(recoilLangSelected)
  const languages = useMemo(() => {
    if (data?.languages?.nodes?.length)
      return data.languages.nodes.sort((a, b) => a.title > b.title ? 1 : -1)
    return []
  }, [data])
  const clickHandler = (e, lang) => {
    e.preventDefault()
    setLang(lang)
    setLangSelected(true)
  }
  return <section className='language-selector'>
    <List>
      {languages.map(({ title, slug }) =>
        <ListItem key={slug}>
          <ListLink onClick={e => clickHandler(e, slug)} href='#'>
            {title}
          </ListLink>
        </ListItem>)}
    </List>
  </section>
}

export default LanguageSelector

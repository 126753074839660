import styled from 'styled-components'

export const List = styled.ul`
margin: 0;
padding: 0;
list-style: none;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

export const ListItem = styled.li`
width: 100%;
margin: 0;
padding: 0;
display: flex;
`

export const ListLink = styled.a`
width: 100%;
padding: 0.5rem 0;
font-size: 1.5rem;

display: flex;
justify-content: center;
align-items: center;

// outline: 1px solid red;
`


import React, { useMemo } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { recoilMonolingual, recoilLang, recoilLangSelected } from '../../recoil/app'

import useTranslation from '../../hooks/useTranslation'

const LanguageSwitcher = () => {
  const data = useStaticQuery(graphql`
    query LanguageSwitcher {
      languages: allWpPage(filter: {parentDatabaseId: {eq: null}, slug: {ne: "language-selector"}}) {
        nodes {
          title
          uri
          slug
        }
      }
    }
  `)
  const {translate, lang} = useTranslation()
  const monolingual = useRecoilValue(recoilMonolingual)
  const setLang = useSetRecoilState(recoilLang)
  const setLangSelected = useSetRecoilState(recoilLangSelected)
  const languages = useMemo(() => {
    if (data?.languages?.nodes?.length)
      return data.languages.nodes.sort((a, b) => a.title > b.title ? 1 : -1)
    return []
  }, [data])

  const changeHandler = ({ target: { value }}) => {
    setLang(value)
    setLangSelected(true)
    navigate(`/${value}/`)
  }

  if (monolingual)
    return <div className='language-switcher'></div>
  else
    return <div className='language-switcher'>
      <select aria-label={translate('Choose language')} value={lang} onChange={changeHandler}>
        {languages.map(({ title, slug }) =>
          <option key={slug} value={slug}>{title}</option>)}
      </select>
    </div>
}

export default LanguageSwitcher

import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { useRecoilState } from 'recoil'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Button } from './Button.styled'
import { recoilNavOpen } from '../../recoil/app'
import useTranslation from '../../hooks/useTranslation'

const SearchButton = () => {
  const {translate, lang} = useTranslation()
  const [ open, setOpen ] = useRecoilState(recoilNavOpen)
  const ref = useRef()
  const clickHandler = e => {
    ref.current.blur()
    setOpen(false)
  }
  return <Button aria-label={translate('Search')} to={`/${lang}/search/`} as={Link} onClick={clickHandler} open={open} ref={ref}>
    <FontAwesomeIcon icon={faSearch} size='2x' />
  </Button>
}

export default SearchButton

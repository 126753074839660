import styled from 'styled-components'

export const Button = styled.button`
width: 3rem;
height: 3rem;
margin: 0 var(--spacing-1);
&:last-child {
  margin-right: var(--spacing-0);
}
padding: 0.5rem;
border: 0;
border-radius: 50%;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
transition: background-color 0.3s;
${props => props.open ? `
color: var(--color-primary-contrast);
background-color: var(--color-primary);
&:hover,
&:focus,
&:active {
  color: var(--color-secondary);
  background-color: var(--color-secondary-contrast);
}
` : `
color: var(--color-secondary-contrast);
background-color: var(--color-secondary);
&:hover,
&:focus,
&:active {
  color: var(--color-primary-contrast);
  background-color: var(--color-primary);
}
`}
`

import styled from 'styled-components'

export const Header = styled.header`
position: fixed;
z-index: 300;
top: 0;
left: 0;
width: 100%;
min-height: 6rem;
display: flex;
justify-content: center;
align-items: center;
> div {
  position: absolute;
  z-index: 300;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    header,
    a,
    button {
      flex: 0 0 auto;
    }
    div {
      margin-right: 0.5rem;
      display: flex;
      flex: 1 0 auto;
      justify-content: flex-end;
    }
  }
}
`

export const HeaderBackground = styled.div`
position: fixed;
z-index: 100;
top: 0;
keft: 0;
width: 100%;
min-height: 6rem;
color: var(--color-text);
background-color: var(--color-background);
`

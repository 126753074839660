import styled from 'styled-components'

export const Navigation = styled.div`
position: fixed;
z-index: 200;
top: 0;
right: 0;
bottom: 0;
left: 0;
padding-top: var(--spacing-24);
overflow-y: auto;
color: var(--color-secondary-contrast);
background-color: var(--color-secondary);
display: flex;
> div {
  height: calc(100% - var(--spacing-24));
  > div {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: space-between;
  }
}
transition: transform 0.3s;
${props => props.open ? `
transform: translateX(0);
` : `
transform: translateX(100%);
`}
`

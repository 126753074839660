import React, { useRef, useState, useEffect, useCallback } from 'react'
import { navigate } from 'gatsby'

import { USER_TYPES, USER_TYPE_NAMES } from '../../../config/app'
import { useSetRecoilState } from 'recoil'
import { recoilPostcode, recoilUserType, recoilUserTypeSelected } from '../../../recoil/app'

import ErrorMessages from '../../ErrorMessages/ErrorMessages'
import { HorizontalBreak, Select, Acknowledge, Continue } from './UserTypeSelector.styled'

import useTranslation from '../../../hooks/useTranslation'
import { Input } from '../../Contacts/Postcode/Postcode.styled'

const UserTypeSelector = () => {
  const submitted = useRef(true)
  const [errors, setErrors] = useState([])
  const [postcode, setPostcode] = useState('')
  const [userType, setUserType] = useState('')
  const [acknowledged, setAcknowledged] = useState(false)
  const {lang, translate} = useTranslation()
  const setRecoilUserType = useSetRecoilState(recoilUserType)
  const setRecoilPostcode = useSetRecoilState(recoilPostcode)
  const setSelected = useSetRecoilState(recoilUserTypeSelected)
  const onChangeUserType = ({target: {value}}) => void setUserType(value)
  const onChangePostcode = ({target: {value}}) => void setPostcode(value.trimStart().toUpperCase().trim())
  const onChangeAcknowledged = () => setAcknowledged(state => !state)

  const validate = useCallback(() => {
    const errors = []
    if (userType === '')
      errors.push(translate('Please select a user type'))
    if (postcode < 3)
      errors.push(translate('Please enter 3 or more characters'))
    if (!acknowledged)
      errors.push(translate('Please acknowledge your agreement'))
    setErrors(errors)
    return errors
  }, [setErrors, acknowledged, postcode, translate, userType]);

  const submitHandler = e => {
    e.preventDefault()
    submitted.current = false
    const errors = validate()
    if (errors.length)
      return
    setRecoilUserType(userType)
    setRecoilPostcode(postcode)
    setSelected(true)
    navigate(`/${lang}/`)
  }
  useEffect(() => {
    if (!submitted.current)
      validate()
  }, [submitted, userType, acknowledged, validate])
  return <section className='user-type-selector'>
    <HorizontalBreak />
    <ErrorMessages errors={errors} />
    <p>
      <label htmlFor='user-type-select'>
        {translate('Please indicate whether you are a professional, carer or other:')}
      </label>
    </p>
    <p>
      <Select id='user-type-select' value={userType} onChange={onChangeUserType}>
        <option value='' defaultValue>{translate('Choose...')}</option>
        {Object.values(USER_TYPES).map(type =>
          <option key={type} value={type}>{translate(USER_TYPE_NAMES[type])}</option>)}
      </Select>
    </p>
    <p>
      <label htmlFor='postcode'>
        {translate('Please enter the first part of your postcode:')}
      </label>
    </p>
    <p>
      <Input id='postcode' onChange={onChangePostcode} value={postcode} type='text' placeholder={translate('Postcode')} />
    </p>
    <p><label><Acknowledge type='checkbox' checked={acknowledged} onChange={onChangeAcknowledged} />{translate('Please check the box to acknowledge the terms and conditions above')}</label></p>
    <p><Continue onClick={submitHandler}>{translate('Continue')}</Continue></p>
  </section>
}

export default UserTypeSelector

import React, { useMemo, useRef } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'

import { LogoContainer } from './Logo.styled'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { recoilNavOpen, recoilAppReady } from '../../../recoil/app'

import useTranslation from '../../../hooks/useTranslation'

import logo from '../../../assets/img/logo.png'
import logoSm from '../../../assets/img/logo-sm.png'
import logoWhite from '../../../assets/img/logo-white.png'
import logoWhiteSm from '../../../assets/img/logo-sm-white.png'

const Container = () => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      wp {
        generalSettings {
          title
        }
      }
    }
  `)
  const {lang} = useTranslation()
  const ready = useRecoilValue(recoilAppReady)
  const setOpen = useSetRecoilState(recoilNavOpen)
  // const setDebug = useSetRecoilState(recoilDebug)
  const ref = useRef()
  const title = useMemo(() => data?.wp?.generalSettings?.title ? data.wp.generalSettings.title : '', [data])
  const to = useMemo(() => ready ? `/${lang}/` : `/`, [ready, lang])
  const clickHandler = e => {
    ref.current.blur()
    setOpen(false)
    // if (e.detail === 5)
    //   setDebug(({STATE, RESET}) => ({STATE: !STATE, RESET: !RESET}));
  }
  return <LogoContainer ready={ready}>
    <Link to={to} onClick={clickHandler} ref={ref}>
      <img className='logo-image' src={ready ? logo : logoWhite} alt={parse(title)} />
      <img className='logo-image-sm' src={ready ? logoSm : logoWhiteSm} alt={parse(title)} />
    </Link>
  </LogoContainer>
}

export default Container

import { atom, atomFamily, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import { DEBUG, MONOLINGUAL, DEFAULT_LANGUAGE, DATA_TIMESTAMP } from '../config/app'

export const { persistAtom } = recoilPersist()

export const recoilDebug = atom({
  key: 'Debug',
  default: DEBUG,
  effects_UNSTABLE: [persistAtom],
})

export const recoilMonolingual = atom({
  key: 'Monolingual',
  default: MONOLINGUAL,
})

export const recoilLang = atom({
  key: 'Lang',
  default: DEFAULT_LANGUAGE,
  effects_UNSTABLE: [persistAtom],
})

export const recoilUserType = atom({
  key: 'UserType',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const recoilLangSelected = atom({
  key: 'LangSelected',
  default: MONOLINGUAL ? true : false,
  effects_UNSTABLE: [persistAtom],
})

export const recoilUserTypeSelected = atom({
  key: 'UserTypeSelected',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

export const recoilAppReady = selector({
  key: 'AppReady',
  get: ({ get }) => get(recoilLangSelected) && get(recoilUserTypeSelected) ? true : false,
})

export const recoilCookiesAccepted = atom({
  key: 'CookiesAccepted',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

export const recoilNavOpen = atom({
  key: 'NavOpen',
  default: false,
})

export const recoilPostcode = atom({
  key: 'Postcode',
  default: '',
  effects_UNSTABLE: [persistAtom],
})

export const recoilCCG = atom({
  key: 'CCG',
  default: '',
  effects_UNSTABLE: [persistAtom],
})

export const recoilContactsReady = selector({
  key: 'ContactsReady',
  get: ({ get }) => get(recoilPostcode) && get(recoilCCG) ? true : false,
})

export const recoilMatchedCCGs = atom({
  key: 'MatchedCCGs',
  default: [],
})

export const recoilData = atomFamily({
  key: 'Data',
  default: [],
  effects_UNSTABLE: uri => [
    ({setSelf}) => {
      const loadData = async () => {
        const csv = await fetch(uri)
        const text = await csv.text()
        setSelf(text)
      }
      loadData()
    },
  ],
})

export const recoilDataTimestamp = atom({
  key: 'DataTimestamp',
  default: DATA_TIMESTAMP,
})

export const recoilDataLastUpdated = atom({
  key: 'DataLastUpdated',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const recoilHasDataChanged = selector({
  key: 'HasDataChanged',
  get: ({ get }) => get(recoilDataTimestamp) !== get(recoilDataLastUpdated) ? true : false,
})

export const recoilSearchSubmitted = atom({
  key: 'SearchSubmitted',
  default: false,
})

export const recoilSearchTerms = atom({
  key: 'SearchTerms',
  default: '',
})

export const recoilSearchResults = atom({
  key: 'SearchResults',
  default: [],
})

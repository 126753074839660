import styled from 'styled-components'

export const Main = styled.main`
height: 100%;
padding-top: var(--spacing-20);
.responsive.alignleft {
  max-width: 40% !important;
  margin-right: 2vw !important;
  float: left !important;
}
@media (max-width: 567px) {
  .responsive.alignleft {
    width: 100% !important;
    max-width: 100% !important;
    margin-right: 0 !important;
    float: none !important;
  }
}
.wp-block-columns {
  display: flex;
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }
  .wp-block-column {
    width: 50%;
  }
}
`

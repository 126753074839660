import React from 'react'
import useTranslation from '../../hooks/useTranslation'

const SkipToContent = () => {
  const {translate} = useTranslation()
  return <p style={{ position: 'absolute', left: '-99999px' }}>
    <a href='#content'>{translate('Skip to content')}</a>
  </p>
}

export default SkipToContent
